<template>
  <v-card>
    <v-card-title id="title" class="pb-0">
      <v-row>
        <v-col cols="2">
          Orders
          <v-btn
            fab
            x-small
            class="ml-2"
            @click="
              getOrders();
              $bus.$emit('refreshBatchList');
            "
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <UserSelect
            v-if="$abilities.includes('update orders')"
            v-model="assignToUser"
            label="Assign"
            autoclear
            class="filter"
            @change="assignSelectedOrders()"
          />
        </v-col>
        <v-col cols="2">
          <v-divider vertical class="mr-6" style="float: left"></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            dense
            label="Order ID"
            clearable
          />
        </v-col>

        <v-col cols="2">
          <StoreSelect
            v-if="$abilities.includes('update stores')"
            v-model="store"
            class="filter"
          />
        </v-col>

        <v-col cols="2">
          <UserSelect
            v-if="$abilities.includes('update users')"
            v-model="user"
            label="Assigned To"
            :show-empty="true"
            class="filter"
          />
        </v-col>

        <v-col cols="2">
          <TagSelect
            v-if="$abilities.includes('update users')"
            v-model="tag"
            label="Tagged With"
            class="filter"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      id="orders"
      v-model="selectedOrders"
      item-key="id"
      :headers="headers"
      :items="orders"
      :items-per-page="options.itemsPerPage"
      :server-items-length="totalRecords"
      :options.sync="options"
      :search="search"
      :loading="loading || assignToUserLoading"
      :multi-sort="true"
      :footer-props="{
        'items-per-page-options': [15, 20, 25, 35, 50, 100, 250],
      }"
      dense
      loading-text="Loading..."
      show-select
    >
      <template #top="{ pagination }">
        <v-data-footer
          id="ordersTableTopBar"
          :pagination="pagination"
          :options.sync="options"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :items-per-page-options="[15, 20, 25, 35, 50, 100, 250]"
        >
          <template #prepend>
            <BatchRemoveButton class="ml-2" />
            <BatchCancelButton class="ml-2" />
            <PickListButton
              :orders="selectedOrders"
              :disabled="pickListButtonDisabled"
              class="ml-2"
            />
            <ShipmentLabels
              :disabled="labelButtonDisabled"
              :shipments="shipments"
              class="ml-2"
            />
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-chip
                  color="red"
                  dark
                  small
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <strong>{{ selectedOrders.length }}</strong>
                </v-chip>
              </template>
              <span>{{ selectedOrders.length }} orders selected</span>
            </v-tooltip>
            <v-spacer />

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="pr-2"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="saveOptions"
                >
                  mdi-content-save
                </v-icon>
              </template>
              <span>Save current sort/pagition options as default.</span>
            </v-tooltip>
          </template>
        </v-data-footer>
      </template>
      <template #[`item.storeOrderId`]="{ item }">
        <a href="#" :class="item.status" @click.prevent="openRecord(item.id)">
          {{ item.storeOrderId }}
        </a>
        <a
          v-if="$abilities.includes('forceDelete orders')"
          :href="apiBaseUrl + '/../nova/resources/orders/' + item.id"
          target="_blank"
          ><v-icon x-small>mdi-link-variant</v-icon>
        </a>
        <a
          v-if="item.storeOrderId && $abilities.includes('forceDelete orders')"
          icon
          x-small
          :href="
            adminBaseUrl +
            '/nova/resources/orders?orders_page=1&orders_search=' +
            item.storeOrderId
          "
          target="_blank"
        >
          <v-icon x-small> mdi-open-in-new </v-icon>
        </a>

        <v-tooltip v-if="item.batch" bottom small>
          <template #activator="{ on, attrs }">
            <v-btn
              plain
              icon
              :to="{ name: 'Orders', query: { batch: item.batch.id } }"
            >
              <v-icon small v-bind="attrs" v-on="on"> mdi-animation </v-icon>
            </v-btn>
          </template>
          <span>Batch {{ item.batch.id }}</span>
        </v-tooltip>
        <AddressValidatedBadge
          v-model="item.address"
          :dense="true"
          style="float: right"
          class="ml-1"
        />
        <ShipmentBadge
          v-if="item.shipments.length > 0"
          :shipments="item.shipments"
          dense
          small
          style="float: right"
          class="ml-1"
        />
      </template>
      <template #[`item.orderedAt`]="{ item }">
        <span style="font-size: smaller">
          <timeago :datetime="item.orderedAt" :auto-update="600"></timeago>
        </span>
      </template>
      <template #[`item.orderTotal`]="{ item }">
        {{ $n(item.orderTotal, "currency") }}
      </template>
      <template #[`item.shipBy`]="{ item }">
        {{ $d(new Date(item.shipBy), "longDay") }}
      </template>
      <template #[`item.notes`]="{ item }">
        <v-icon
          :color="item.notes ? '' : 'grey lighten-1'"
          @click="
            noteOrder = item;
            showNotesForm = true;
          "
        >
          mdi-note-edit
        </v-icon>
      </template>
      <template #[`item.user.name`]="{ item }">
        <span v-if="item.user">{{ item.user.name }}</span>
        <span v-else>
          <v-btn
            v-if="item.category != '(Multiple)'"
            icon
            x-small
            :href="
              adminBaseUrl +
              '/nova/resources/items?items_page=1&items_search=' +
              item.category
            "
            target="_blank"
          >
            <v-icon v-if="$abilities.includes('forceDelete orders')" small>
              mdi-open-in-new
            </v-icon>
          </v-btn>
        </span>
      </template>
      <template #[`item.orderItems`]="{ item }">
        <v-badge
          v-for="orderItem in item.orderItems"
          :key="orderItem.id"
          class="mr-2"
          left
          overlap
          color="red"
          :value="orderItem.quantity > 1"
        >
          <template #badge>
            <span>{{ orderItem.quantity }}</span>
          </template>
          <v-chip color="green" text-color="white" small>
            {{ orderItem.sku }}
          </v-chip>
        </v-badge>
      </template>
      <template #[`item.tags`]="{ item }">
        <template v-for="tag in item.tags">
          <v-tooltip :key="tag.id" bottom>
            <template #activator="{ on, attrs }">
              <v-chip
                label
                class="px-1 py-0 mr-1"
                :color="tag.color"
                v-bind="attrs"
                v-on="on"
              ></v-chip>
            </template>
            <span>{{ tag.name }}</span>
          </v-tooltip>
        </template>
      </template>
    </v-data-table>
    <OrderDetail v-model="showOrderDetail" :order-id="orderId" />
    <OrderNotesForm v-model="showNotesForm" :order="noteOrder" />
  </v-card>
</template>

<script>
import Vue from "vue";
import { debounce } from "../plugins/debounce";
import OrderDetail from "../components/OrderDetail.vue";
import BatchCancelButton from "../components/ui/BatchCancelButton.vue";
import BatchRemoveButton from "../components/ui/BatchRemoveButton.vue";
import StoreSelect from "../components/input/StoreSelect.vue";
import UserSelect from "../components/input/UserSelect.vue";
import TagSelect from "../components/input/TagSelect.vue";
import OrderNotesForm from "../components/input/OrderNotesForm.vue";
import AddressValidatedBadge from "../components/ui/AddressValidatedBadge.vue";
import ShipmentBadge from "../components/ui/ShipmentBadge.vue";
import PickListButton from "../components/ui/PickListButton.vue";
import ShipmentLabels from "../components/ShipmentLabels.vue";

export default {
  name: "OrdersView",
  components: {
    OrderDetail,
    BatchCancelButton,
    BatchRemoveButton,
    StoreSelect,
    UserSelect,
    TagSelect,
    OrderNotesForm,
    AddressValidatedBadge,
    ShipmentBadge,
    PickListButton,
    ShipmentLabels,
  },
  data() {
    return {
      include:
        "address,batch,orderItems,shipments,shipments.attachments,shipments.order,shipments.order.store,shipments.orderItems,shipments.syncs,shipments.shippingService.carrier,store,tags,user",
      adminBaseUrl: "",
      apiBaseUrl: "",
      batch: {},
      noteOrder: {},
      showNotesForm: false,
      orders: [],
      orderId: 0,
      assignToUser: {},
      store: {},
      user: {},
      tag: {},
      selectedOrders: [],
      pickListButtonDisabled: true,
      labelButtonDisabled: true,
      loading: false,
      assignToUserLoading: false,
      search: "",
      pageCount: 1,
      totalRecords: 0,
      showOrderDetail: false,
      options: {
        itemsPerPage: 100,
        sortBy: ["category", "orderItemQuantity"],
        sortDesc: [true, true],
      },
      headers: [
        { text: "Order #", value: "storeOrderId" },
        { text: "Age", value: "orderedAt" },
        {
          text: "Ship By",
          value: "shipBy",
          align: Vue.prototype.$abilities.includes("delete users")
            ? ""
            : " d-none",
        },
        { text: "State", value: "address.state", sortable: false },
        { text: "Notes", value: "notes" },
        { text: "Assigned To", value: "user.name", sortable: false },
        { text: "SKU", value: "category" },
        { text: "QTY", value: "orderItemQuantity" },
        { text: "Location", value: "location" },
        { text: "Tags", value: "tags", sortable: false },
        { text: "Items", value: "orderItems", width: "25%" },
      ],
    };
  },
  computed: {
    shipments: {
      get() {
        let shipments = [];
        this.selectedOrders.forEach((order) => {
          order.shipments.forEach((shipment) => {
            shipment.order = order;
            shipments.push(shipment);
          });
        });
        return shipments;
      },
    },
  },
  watch: {
    selectedOrders(value) {
      this.$bus.$emit("selectedOrders", value);

      this.pickListButtonDisabled = value.length > 0 ? false : true;

      this.labelButtonDisabled = true;
      value.forEach((order) => {
        order.shipments.forEach((shipment) => {
          if (
            (!shipment.trackingId && shipment.shippingServiceId) ||
            shipment.label
          ) {
            this.labelButtonDisabled = false;
            return;
          }
        });
      });
    },
    showOrderDetail: function (newValue) {
      if (!newValue) {
        this.getOrders();
      }
      if (!this.showOrderDetail) {
        let query = Object.assign({}, this.$route.query);
        delete query.id;
        this.$router.replace({ query });
      }
    },
    showNotesForm: function (newValue) {
      if (!newValue) {
        this.getOrders();
      }
    },
    options: {
      handler() {
        this.getOrders();

        if (this.$route.query.id) {
          this.openRecord(this.$route.query.id);
        }
      },
      deep: true,
    },
    batch() {
      this.getOrders();
    },
    search(...args) {
      this.debouncedSearch(...args);
    },
    $route() {
      this.getOrders();
    },
    store() {
      this.getOrders();
    },
    user() {
      this.getOrders();
    },
    tag() {
      this.getOrders();
    },
  },
  mounted() {
    this.apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
    this.adminBaseUrl = process.env.VUE_APP_ADMIN_BASE_URL;

    this.options =
      JSON.parse(localStorage.getItem("orderOptions")) ?? this.options;

    this.$bus.$on("refreshOrders", () => {
      this.getOrders();
    });

    this.$bus.$on("openOrderRecord", (orderId) => {
      this.search = orderId;
    });

    this.$bus.$on("clearSelectedOrders", () => {
      this.clearSelectedOrders();
    });

    this.$bus.$on("clearOrderOptions", () => {
      this.clearOrderOptions();
    });
  },
  created() {
    this.debouncedSearch = debounce(() => {
      this.getOrders();
    }, 1000);
  },
  methods: {
    saveOptions: function () {
      localStorage.setItem("orderOptions", JSON.stringify(this.options));
      this.$bus.$emit("Message", "Current sort/pagition options saved.");
    },
    assignSelectedOrders: function () {
      if (!this.assignToUser) return;

      this.assignToUserLoading = true;

      let orders = [];

      this.selectedOrders.forEach((order) => {
        orders.push({ id: order.id.toString(), type: "orders" });
      });

      this.$models.User.createRelationships(
        this.assignToUser.id,
        "order",
        orders
      )
        .then(() => {
          this.assignToUserLoading = false;
          this.getOrders();
          this.clearSelectedOrders();

          this.$bus.$emit("Message", "Orders assigned");
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
    clearSelectedOrders: function () {
      this.selectedOrders = [];
    },
    clearOrderOptions: function () {
      this.options.sortBy = [];
      this.options.sortDesc = [false];
      this.options.page = 1;

      this.options =
        JSON.parse(localStorage.getItem("orderOptions")) ?? this.options;
    },
    selectOrders: function (orders) {
      this.selectedOrders = orders;
    },
    openRecord: function (id) {
      this.orderId = Number(id);
      this.showOrderDetail = true;

      if (id != this.$route.query.id) {
        this.$router
          .push({
            query: {
              ...{ id: id },
              ...this.$route.query,
            },
          })
          .catch(() => {});
      }
    },
    getOrders: function () {
      // if (this.loading) return;

      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filter = {
        page: {
          number: page,
          size: itemsPerPage,
        },
        filter: {},
        sort: "",
        include: this.include,
      };

      let filterSortArray = [];
      sortBy.map((element, index) => {
        filterSortArray.push((sortDesc[index] ? "-" : "") + sortBy[index]);
      });
      filter.sort = filterSortArray.join(",");

      if (filter.sort.length < 1) {
        delete filter.sort;
      }

      if (this.search) {
        filter.filter = {};
        filter.filter.search = this.search;
      }

      if (this.tag) {
        filter.filter.tags = {};
        filter.filter.tags.id = [this.tag.id];
      }

      if (this.store) filter.filter.storeId = this.store.id;
      if (this.user) filter.filter.userId = this.user.id;
      if (this.batch) filter.filter.batchId = this.batch.id;
      if (this.$route.query.batch) {
        filter.filter.batchId = this.$route.query.batch;
        filter.page.size = 500;
        this.$bus.$emit("refreshBatchList");
      } else if (this.$route.query.status)
        filter.filter.status = this.$route.query.status;

      this.$models.Order.all(filter).then((response) => {
        if (this.loading) {
          this.orders = response.data;
          this.pageCount = response.meta.page.lastPage;
          this.totalRecords = response.meta.page.total;
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style>
.filter {
  max-width: 20em;
  margin-left: 2em;
}

#ordersTableTopBar {
  border-bottom: 1px solid lightgrey;
  border-top: none;
}

.v-chip.v-size--default {
  height: 22px;
}

a {
  text-decoration: none;
}

a.complete,
a.canceled {
  font-style: italic;
  color: grey !important;
}
</style>
