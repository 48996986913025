<template>
  <v-card>
    <v-card-title id="title" class="pb-0">
      <v-row>
        <v-col cols="1">
          Shipments
          <v-btn
            fab
            x-small
            class="ml-2"
            @click="
              getShipments();
              $bus.$emit('refreshShipmentBatchList');
            "
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <UserSelect
            v-if="$abilities.includes('update shipments')"
            v-model="assignToUser"
            label="Assign Shipment"
            autoclear
            class="action"
            @change="assignSelectedShipments()"
          />
        </v-col>
        <v-col cols="2">
          <v-divider vertical class="mr-6" style="float: left"></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            dense
            label="Order ID"
            clearable
          />
        </v-col>
        <v-col cols="2">
          <StoreSelect
            v-if="$abilities.includes('update stores')"
            v-model="store"
            class="filter"
          />
        </v-col>
        <v-col cols="2">
          <TagSelect
            v-if="$abilities.includes('view shipments')"
            v-model="tag"
            label="Tagged With"
            class="filter"
          />
        </v-col>
        <v-col cols="3">
          <UserSelectMultiple
            v-if="$abilities.includes('update users')"
            v-model="users"
            label="Assigned To"
            :show-empty="true"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      id="shipments"
      v-model="selectedShipments"
      item-key="id"
      :headers="headers"
      :items="shipments"
      :items-per-page="options.itemsPerPage"
      :server-items-length="totalRecords"
      :options.sync="options"
      :search="search"
      :loading="loading || assignToUserLoading"
      :multi-sort="true"
      :footer-props="{
        'items-per-page-options': [15, 20, 25, 35, 50, 100, 250],
      }"
      dense
      loading-text="Loading..."
      show-select
    >
      <template #top="{ pagination }">
        <v-data-footer
          id="shipmentsTableTopBar"
          :pagination="pagination"
          :options.sync="options"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :items-per-page-options="[15, 20, 25, 35, 50, 100, 250]"
        >
          <template #prepend>
            <ShipmentBatchRemoveButton class="ml-2" />
            <BatchCancelButton class="ml-2" />
            <PickListButton
              :shipments="selectedShipments"
              :disabled="pickListButtonDisabled"
              class="ml-2"
            />
            <ShipmentLabels
              :disabled="labelButtonDisabled"
              :shipments="shipments"
              class="ml-2"
            />
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-chip
                  color="red"
                  dark
                  small
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <strong>{{ selectedShipments.length }}</strong>
                </v-chip>
              </template>
              <span>{{ selectedShipments.length }} shipments selected</span>
            </v-tooltip>
            <v-spacer />

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="pr-2"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="saveOptions"
                >
                  mdi-content-save
                </v-icon>
              </template>
              <span>Save current sort/pagition options as default.</span>
            </v-tooltip>
          </template>
        </v-data-footer>
      </template>
      <template #[`item.id`]="{ item }">
        <strong :class="item.trackingId ? 'complete' : ''">
          {{ item.id }}
        </strong>
        <a
          v-if="$abilities.includes('forceDelete shipments')"
          :href="apiBaseUrl + '/../nova/resources/shipments/' + item.id"
          target="_blank"
          ><v-icon x-small>mdi-link-variant</v-icon>
        </a>
      </template>
      <template #[`item.order`]="{ item }">
        <a
          v-if="item.order && $abilities.includes('restore orders')"
          href="#"
          :class="item.order.status"
          @click.prevent="openRecord(item.order.id)"
        >
          {{ item.order.storeOrderId }}
        </a>
        <a
          v-else-if="item.order"
          href="#"
          :class="item.order.status"
          @click="
            noteOrder = item.order;
            showNotesForm = true;
          "
        >
          {{ item.order.storeOrderId }}
        </a>
        <a
          v-if="item.order && $abilities.includes('forceDelete orders')"
          :href="apiBaseUrl + '/../nova/resources/orders/' + item.order.id"
          target="_blank"
          ><v-icon x-small>mdi-link-variant</v-icon>
        </a>
        <a
          v-if="
            item.order.storeOrderId && $abilities.includes('forceDelete orders')
          "
          icon
          x-small
          :href="
            adminBaseUrl +
            '/nova/resources/orders?orders_page=1&orders_search=' +
            item.order.storeOrderId
          "
          target="_blank"
        >
          <v-icon x-small> mdi-open-in-new </v-icon>
        </a>

        <v-tooltip v-if="item.batch" bottom small>
          <template #activator="{ on, attrs }">
            <v-btn
              plain
              icon
              :to="{ name: 'Shipments', query: { batch: item.batch.id } }"
            >
              <v-icon small v-bind="attrs" v-on="on"> mdi-animation </v-icon>
            </v-btn>
          </template>
          <span>Batch {{ item.batch.id }}</span>
        </v-tooltip>
        <AddressValidatedBadge
          v-if="item.order"
          v-model="item.order.address"
          :dense="true"
          style="float: right"
          class="ml-1"
        />
        <ShipmentBadge
          v-if="item.length > 0"
          :shipments="[item]"
          dense
          small
          style="float: right"
          class="ml-1"
        />
      </template>
      <template #[`item.createdAt`]="{ item }">
        <span style="font-size: smaller">
          <timeago :datetime="item.createdAt" :auto-update="600"></timeago>
        </span>
      </template>
      <template #[`item.order.orderTotal`]="{ item }">
        {{ $n(item.order.orderTotal, "currency") }}
      </template>
      <template #[`item.order.shipBy`]="{ item }">
        <span v-if="item.order">
          {{ $d(new Date(item.order.shipBy), "longDay") }}
        </span>
      </template>
      <template #[`item.order.notes`]="{ item }">
        <v-icon
          v-if="item.order"
          :color="item.order.notes ? '' : 'grey lighten-1'"
          @click="
            noteOrder = item.order;
            showNotesForm = true;
          "
        >
          mdi-note-edit
        </v-icon>
      </template>
      <template #[`item.user.name`]="{ item }">
        <span v-if="item.user">{{ item.user.name }}</span>
        <span v-else>
          <v-btn
            v-if="item.order.category != '(Multiple)'"
            icon
            x-small
            :href="
              adminBaseUrl +
              '/nova/resources/items?items_page=1&items_search=' +
              item.order.category
            "
            target="_blank"
          >
            <v-icon v-if="$abilities.includes('forceDelete shipments')" small>
              mdi-open-in-new
            </v-icon>
          </v-btn>
        </span>
      </template>
      <template #[`item.order.tags`]="{ item }">
        <span v-if="item.order">
          <template v-for="orderTag in item.order.tags">
            <v-tooltip :key="orderTag.id" bottom>
              <template #activator="{ on, attrs }">
                <v-chip
                  label
                  class="px-1 py-0 mr-1"
                  :color="orderTag.color"
                  v-bind="attrs"
                  v-on="on"
                ></v-chip>
              </template>
              <span>{{ orderTag.name }}</span>
            </v-tooltip>
          </template>
        </span>
      </template>
      <template #[`item.orderItems`]="{ item }">
        <v-badge
          v-for="shipmentItem in item.orderItems"
          :key="shipmentItem.id"
          class="mr-2"
          left
          overlap
          color="red"
          :value="shipmentItem.quantity > 1"
        >
          <template #badge>
            <span>{{ shipmentItem.quantity }}</span>
          </template>
          <v-chip color="green" text-color="white" small>
            {{ shipmentItem.sku }}
          </v-chip>
        </v-badge>
      </template>
      <template #[`item.orderItems.location`]="{ item }">
        <v-chip
          v-for="shipmentItem in item.orderItems"
          :key="'location_' + shipmentItem.id"
          class="mr-1"
          color="black"
          outlined
          small
        >
          {{ shipmentItem.location }}
        </v-chip>
      </template>
    </v-data-table>
    <OrderDetail
      v-model="showOrderDetail"
      :order-id="orderId"
      :orders="currentOrders"
    />
    <OrderNotesForm v-model="showNotesForm" :order="noteOrder" />
  </v-card>
</template>

<script>
import Vue from "vue";
import { debounce } from "../plugins/debounce";
import OrderDetail from "../components/OrderDetail.vue";
import BatchCancelButton from "../components/ui/BatchCancelButton.vue";
import ShipmentBatchRemoveButton from "../components/ui/ShipmentBatchRemoveButton.vue";
import UserSelect from "../components/input/UserSelect.vue";
import OrderNotesForm from "../components/input/OrderNotesForm.vue";
import AddressValidatedBadge from "../components/ui/AddressValidatedBadge.vue";
import ShipmentBadge from "../components/ui/ShipmentBadge.vue";
import PickListButton from "../components/ui/PickListButton.vue";
import ShipmentLabels from "../components/ShipmentLabels.vue";
import StoreSelect from "../components/input/StoreSelect.vue";
import TagSelect from "../components/input/TagSelect.vue";
import UserSelectMultiple from "@/components/input/UserSelectMultiple.vue";

export default {
  name: "ShipmentsView",
  components: {
    OrderDetail,
    BatchCancelButton,
    ShipmentBatchRemoveButton,
    UserSelect,
    UserSelectMultiple,
    OrderNotesForm,
    AddressValidatedBadge,
    ShipmentBadge,
    PickListButton,
    ShipmentLabels,
    StoreSelect,
    TagSelect,
  },
  data() {
    return {
      include:
        "attachments,shippingService.carrier,orderItems,order,order.address,order.store,order.tags,syncs,batch,user",
      adminBaseUrl: "",
      apiBaseUrl: "",
      batch: {},
      noteOrder: {},
      showNotesForm: false,
      shipments: [],
      orderId: 0,
      store: {},
      assignToUser: {},
      user: {},
      users: [],
      tag: {},
      currentOrders: [],
      selectedOrders: [],
      selectedShipments: [],
      pickListButtonDisabled: true,
      labelButtonDisabled: true,
      loading: false,
      assignToUserLoading: false,
      search: "",
      pageCount: 1,
      totalRecords: 0,
      showOrderDetail: false,
      options: {
        itemsPerPage: 100,
        sortBy: ["orderItems"],
        sortDesc: [true],
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Order #", value: "order" },
        { text: "Age", value: "createdAt" },
        { text: "Notes", value: "order.notes", sortable: false },
        {
          text: "Ship By",
          value: "order.shipBy",
          align: Vue.prototype.$abilities.includes("delete users")
            ? ""
            : " d-none",
        },
        { text: "State", value: "order.address.state", sortable: false },
        {
          text: "Assigned To",
          value: "user.name",
          sortable: false,
          align: Vue.prototype.$abilities.includes("delete users")
            ? ""
            : " d-none",
        },
        { text: "Order Tags", value: "order.tags", sortable: false },
        {
          text: "Item Locations",
          value: "orderItems.location",
          sortable: false,
        },
        { text: "Items", value: "orderItems", width: "25%" },
      ],
    };
  },
  watch: {
    selectedShipments(value) {
      this.$bus.$emit("selectedShipments", value);

      if (value.length > 0 && this.$route.query.batch) {
        this.pickListButtonDisabled = false;
      } else {
        this.pickListButtonDisabled = true;
      }

      this.labelButtonDisabled = true;
      this.selectedOrders = [];
      value.forEach((shipment) => {
        if (this.selectedOrders.indexOf(shipment.order) === -1) {
          this.selectedOrders.push(shipment.order);
        }
        if (
          this.$route.query.batch &&
          ((!shipment.trackingId && shipment.shippingServiceId) ||
            shipment.label)
        ) {
          this.labelButtonDisabled = false;
          return;
        }
      });
    },
    showOrderDetail: function (newValue) {
      if (!newValue) {
        this.getShipments();
      }
      if (!this.showOrderDetail) {
        let query = Object.assign({}, this.$route.query);
        delete query.id;
        this.$router.replace({ query });
      }
    },
    showNotesForm: function (newValue) {
      if (!newValue) {
        this.getShipments();
      }
    },
    options: {
      handler() {
        this.getShipments();

        if (this.$route.query.id) {
          this.openRecord(this.$route.query.id);
        }
      },
      deep: true,
    },
    batch() {
      this.getShipments();
    },
    search(...args) {
      this.debouncedSearch(...args);
    },
    $route() {
      this.getShipments();
    },
    store() {
      this.getShipments();
    },
    user() {
      this.getShipments();
    },
    users() {
      this.getShipments();
    },
    tag() {
      this.getShipments();
    },
  },
  created() {
    this.debouncedSearch = debounce(() => {
      this.getShipments();
    }, 1000);
  },
  mounted() {
    this.apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
    this.adminBaseUrl = process.env.VUE_APP_ADMIN_BASE_URL;

    this.options =
      JSON.parse(localStorage.getItem("shipmentOptions")) ?? this.options;

    this.$bus.$on("refreshShipments", () => {
      this.getShipments();
    });

    this.$bus.$on("openShipmentOrderRecord", (orderId) => {
      this.search = orderId;
    });

    this.$bus.$on("clearSelectedShipments", () => {
      this.clearSelectedShipments();
    });

    this.$bus.$on("clearShipmentOptions", () => {
      this.clearShipmentOptions();
    });
  },
  methods: {
    saveOptions: function () {
      localStorage.setItem("shipmentOptions", JSON.stringify(this.options));
      this.$bus.$emit("Message", "Current sort/pagition options saved.");
    },
    assignSelectedShipments: function () {
      if (!this.assignToUser) return;

      this.assignToUserLoading = true;
      this.orderId = 1;
      let shipments = [];

      this.selectedShipments.forEach((shipment) => {
        shipments.push({ id: shipment.id.toString(), type: "shipments" });
      });

      if (this.assignToUser.address) {
        this.$models.Address.createRelationships(
          this.assignToUser.address.id,
          "shipment",
          shipments
        )
          .then(() => {
            this.$bus.$emit("Message", "Shipments location assigned");
          })
          .catch((e) => this.$bus.$emit("Error", e));
      }

      this.$models.User.createRelationships(
        this.assignToUser.id,
        "shipment",
        shipments
      )
        .then(() => {
          this.assignToUserLoading = false;
          this.getShipments();
          this.clearSelectedShipments();

          this.$bus.$emit("Message", "Shipments user assigned");
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
    clearSelectedShipments: function () {
      this.selectedShipments = [];
    },
    clearShipmentOptions: function () {
      this.options.sortBy = [];
      this.options.sortDesc = [false];
      this.options.page = 1;

      this.options =
        JSON.parse(localStorage.getItem("shipmentOptions")) ?? this.options;
    },
    selectShipments: function (shipments) {
      this.selectedShipments = shipments;
    },
    openRecord: function (id) {
      this.orderId = Number(id);
      this.showOrderDetail = true;

      if (id != this.$route.query.id) {
        this.$router
          .push({
            query: {
              ...{ id: id },
              ...this.$route.query,
            },
          })
          .catch(() => {});
      }
    },
    getShipments: function () {
      // if (this.loading) return;

      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filter = {
        page: {
          number: page,
          size: itemsPerPage,
        },
        filter: {},
        sort: "",
        include: this.include,
      };

      let filterSortArray = [];
      sortBy.map((element, index) => {
        filterSortArray.push((sortDesc[index] ? "-" : "") + sortBy[index]);
      });
      filter.sort = filterSortArray.join(",");

      if (filter.sort.length < 1) {
        delete filter.sort;
      }

      if (this.search) {
        filter.filter = {};
        filter.filter.search = this.search;
      }

      if (this.store) {
        filter.filter.order = { storeId: this.store.id };
      }
      if (this.tag) {
        if (!filter.filter.order) {
          filter.filter.order = {};
        }
        filter.filter.order.tags = { id: [this.tag.id] };
      }

      if (this.user) filter.filter.userId = this.user.id;
      if (this.users) filter.filter.userIds = this.users;
      if (this.batch) filter.filter.batchId = this.batch.id;
      if (this.$route.query.batch) {
        filter.filter.batchId = this.$route.query.batch;
        filter.page.size = 500;
        this.$bus.$emit("refreshShipmentBatchList");
      } else if (this.$route.query.shipped) {
        filter.filter.shipped = this.$route.query.shipped;
      }

      this.$models.Shipment.all(filter).then((response) => {
        this.shipments = response.data;
        this.pageCount = response.meta.page.lastPage;
        this.totalRecords = response.meta.page.total;
        this.currentOrdersOrders = [];
        this.shipments.forEach((shipment) => {
          this.currentOrders.push(shipment.order);
        });
        this.loading = false;
      });
    },
  },
};
</script>

<style>
.action {
  max-width: 20em;
}

#shipmentsTableTopBar {
  border-bottom: 1px solid lightgrey;
  border-top: none;
}

.v-chip.v-size--default {
  height: 22px;
}

a {
  text-decoration: none;
}

.complete,
.canceled {
  font-style: italic;
  color: grey !important;
}
</style>
